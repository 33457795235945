import { createSlice } from '@reduxjs/toolkit'
import { DailyNetworkStats } from '@daily-co/daily-js'

export interface DailyProviderState {
  callStats: {
    [key: string]: {
      quality: DailyNetworkStats['quality']
      threshold: DailyNetworkStats['threshold']
    }
  }
}
const initialState: DailyProviderState = {
  callStats: {},
}

const dailySlice = createSlice({
  name: 'daily',
  initialState,
  reducers: {
    callStatsUpdated(state, action) {
      const { stats, id } = action.payload
      state.callStats[id] = stats
    },
  },
})

export const { callStatsUpdated } = dailySlice.actions

const selectStats = (state) => state.daily.callStats

export const selectStatsById = (state, userId) => {
  return selectStats(state)[userId]
}

export default dailySlice.reducer
